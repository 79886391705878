.container-row {
  .table-striped-rows tr:nth-child(2n) td {
    background-color: $dark;
  }
  .table-striped-rows {
    overflow-x: scroll;
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 23px 0 23px 20px;
    }
  }
  thead[class*='ant-table-thead'] th {
    background-color: $dark !important;
  }
  &__col {
    background-color: $dark;
    position: relative;
    .header {
      &__container {
        display: flex;
        &__title-container {
          @include flex;
          &__text {
            @include fontStyles(2.4rem, $bold);
            padding-left: 2rem;
            color: white;
          }
        }
      }
      .menu-row {
        row-gap: 0.5rem !important;
        &__col {
          .input-search {
            background: $secondary;
            .ant-input-search-button {
              padding: 0 0.8rem;
              background-color: $primary;
              border: none;
              outline: none;
            }
          }
          .range-picker-filter {
            background: $secondary;
            .ant-picker-suffix {
              color: inherit;
            }
          }
          .dropdown-filter {
            width: 15rem;
            background: $secondary;
          }
        }
      }
    }
    .table-row {
      &__col {
        min-height: 90%;
      }
    }
  }
}
.results-delete-icon {
  font-size: 2rem;
  color: #737373;
  background-color: transparent;
  transition: color 0.3s ease;

  &:hover {
    color: $offWhite;
  }
}

.ant-table-thead > tr > .ant-table-cell {
  font-weight: 700;
}
.answer_result_item {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 2rem;
  gap: 2rem;
}
.ant-table-pagination-right {
  justify-content: center;
}
.ant-table-pagination.ant-pagination {
  margin: 4rem 0;
}
.ant-table-pagination.ant-pagination {
  margin: 4rem 0;
}
