.start-wrapper__padding {
  padding: 20px;
}

.contestModalForm {
  padding: 60px 73px 0 73px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.termsLink {
  color: $primary;
}

.redStar {
  color: #a61d24;
}

.contestModalButtonContainer {
  text-align: center;
  margin-top: 10px;
}

.contestModalButton {
  height: 4rem;
  font-family: 'SF Pro Text', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: $primeWhite !important;
  letter-spacing: 0.8px;
  border-radius: 0.8rem;
  border: 1px solid #6089FB;
  background: $primary;
  outline: none;
  transition: 0.2s;
  padding: 0.8rem 1.2rem;
}