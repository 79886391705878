.cm-s-material-darker .CodeMirror-guttermarker,
.cm-s-material-darker .CodeMirror-guttermarker-subtle,
.cm-s-material-darker .CodeMirror-linenumber {
  font-style: normal;
  font-size: 1.6rem;
}

.CodeMirror pre.CodeMirror-line {
  font-size: 1.6rem;
}

.cm-s-material-darker.CodeMirror {
  font-size: 1.5rem;
}

.CodeMirror {
  border: 1px solid #454545 !important;
  height: auto !important;
}
