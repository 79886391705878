.start-wrapper {
  @include flex-center;
  min-height: 100vh;
  width: 100vw;
  background: $primaryBackgroud;
}
.start-container {
  width: 80rem;
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  color: #e5e5e5;
  letter-spacing: 0.2px;
  background: #242424;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  border: 1px solid $darkerGrey;
  padding-bottom: 4rem;
  &__initial {
    font-size: 1.8rem !important;
    font-weight: normal !important;
    padding-bottom: 2rem;
  }
  &__title {
    margin-bottom: 3.1rem !important;
    font-weight: $bold !important;
  }

  &__logo-container {
    @include flex-center;
    width: 100%;
    height: 11.2rem;
    background: #2a2a2a;
    border-bottom: 1px solid $darkerGrey;
    border-radius: 8px 8px 0 0;
  }

  &__instructions {
    padding: 6rem 7.3rem;
    border-bottom: 1px solid $darkerGrey;
    &__contesttitle {
      font-weight: 600;
      color: #0acf83;
    }
    &__contestdescription {
      line-height: 1.2;
      .wmde-markdown.markdown-preview p {
        margin-top: 1.5rem;
      }
    }
  }

  &__instructions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ant-typography:nth-child(2) {
      margin-bottom: 3.1rem !important;
    }
    .ant-typography:nth-child(3) {
      margin-bottom: 3.1rem !important;
    }

    .ant-typography:nth-child(4) {
      margin-bottom: 3.1rem !important;
    }

    .ant-typography:nth-child(5) {
      margin-bottom: 3.1rem !important;
    }

    .career-link {
      font-size: 16px;
      padding: 0;
      color: #6089FB;
    }

    .career-link:hover {
      color: #597ef7;
    }
  }
  &__contact {
    padding: 5.2rem 7.3rem;
    border-bottom: 1px solid $darkerGrey;
    .ant-typography:nth-child(1) {
      margin-bottom: 2.1rem !important;
    }
  }
  &__info {
    display: flex;
    padding: 4rem 7.3rem 0 7.3rem;

    @media (max-width: $tablet-width) {
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
    }
    .ant-typography:nth-child(1) {
      margin-bottom: 2rem !important;
    }

    &--column {
      max-width: 70%;
      font-size: 1.8rem;
      
      @media (max-width: $tablet-width) {
        max-width: unset;
      }

      .ant-typography:nth-child(3) {
        margin-top: 1rem;
      }
    }

    &--column > .ant-typography {
      @media (max-width: $tablet-width) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__text {
    margin-left: 0.7rem;

    @media (max-width: $tablet-width) {
      margin-left: unset;
    }
  }

  &__text--green {
    color: $success;
    font-size: 1.8rem;
    margin-left: 1.2rem;
  }
  &__text--red {
    color: $paleRed;
  }
  &__icon {
    margin-left: 1.2rem;
    
    @media (max-width: $tablet-width) {
      margin-left: unset;
    }
  }
}

.not-active-test {
  padding-bottom: 0;

  .start-container__instructions {
    border-bottom: none;
  }
}

.custom-button--submit-start {
  margin-top: 4rem
}