@import './GenerateTestForm/GenerateTestForm.scss';
@import './GeneratedTestForm/GeneratedTestForm.scss';

.modal-generate-container {
  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid black;
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-repeat: initial;
    border-top: 0;
    border-top-style: initial;
    border-top-color: initial;
    border-left: 0;
    border-left-style: initial;
    border-left-color: initial;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #434343;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: #0fb474;
      border-color: #0fb474;
      :active {
        border-color: #0fb474;
      }
      ::after {
        border: 1px solid #0fb474;
      }
    }
  }

  .checkbox-option {
    width: 100%;
    margin-left: 0.2rem;
    &__box {
      display: flex;
      flex-direction: column;
    }
  }
  &-col {
    border-radius: $radius-sm;
    background: $dark;
    width: 100%;
    form {
      height: 100%;
    }
  }
}
.elements-top-margin-first {
  margin-top: 3.1rem;
}
.elements-top-margin {
  text-align: start;
  margin-top: 3.5rem;
}
.elements-top-margin-checkbox {
  margin-top: 0.4rem;
}
.element-top-margin--minimal {
  margin-top: 0.9rem;
}
.elements-label {
  color: $stormDast;
}
.generate-test-modal {
  width: 54rem !important;
  .ant-modal-header {
    background: none;
    border-bottom: none;
    margin: 0 4rem;
    padding: 3rem 0;
    padding-bottom: 0;

    .ant-modal-title {
      @include fontStyles(1.8rem, $normal);
      color: $primeWhite;
    }
  }
}
