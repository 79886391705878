.table-striped-rows tr:nth-child(2n) td {
  background-color: $dark;
}
.table-stripped-rows {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 23px 16px;
  }
}
thead[class*='ant-table-thead'] th {
  background-color: $dark !important;
}
.container-internal-quizzeslist__row {
  .container-internal-quizzeslist__col {
    background-color: $dark;
    overflow: hidden;
    position: relative;
    .tests-table__row {
      padding-left: 2rem;
    }
    .header-list-quizzes {
      margin-top: 2%;
      @include flex($justify: center);
      flex-wrap: wrap;
      .title-container__col {
        .title-container {
          .title {
            @include fontStyles(2.4rem, $bold);
            color: white;
            margin: 0;
          }
        }
      }
      .search-col {
        margin-left: 1rem;
      }
      .container-menu-quizzes__row {
        row-gap: 0.5rem !important;
        .container-menu-quizzes__col {
          .input-search {
            background: $secondary;
            .ant-input-search-button {
              padding: 0 0.8rem;
              background-color: $primary;
              border: none;
              outline: none;
            }
          }
          .dropdown-filter {
            width: 10em;
            background: $secondary;
            .ant-select-selection-placeholder {
              color: inherit;
            }
            .ant-select-arrow {
              color: inherit;
            }
            .dropdown-button {
              width: 100%;
            }
          }
        }
      }
    }
    .quizzes-table__row {
      .quizzes-table__col {
        min-height: 90%;
      }
    }
  }
  .inactive-button, .active-button {
    width: 10rem;
  }
  .inactive-button.custom-button--generate {
    background-color: $active-button;
    &:hover, &:focus {
      border: solid 1px $active-button !important;
      background-color: $mountainMeadow !important;
    }
  }
  .active-button.custom-button--generate {
    background-color: $inactive-button;
    &:hover, &:focus {
      border: solid 1px $inactive-button !important;
      background-color: $inactive-button-bright !important;
    }
  }
  .ant-table-row {
    &:hover {
      .button.active-button {
        background-color: $inactive-button-bright;
      }
      .button.inactive-button {
        background-color: $mountainMeadow;
      }
    }
  }
}
.color-hidden {
  background-color: $hidden;
}
.color-hidden-text {
  color: $hidden-text;
}
