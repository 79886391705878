.contact-modal {
  .ant-modal-body {
    font-size: 1.6rem;
    margin-left:0.9rem !important;
    padding: 2rem !important;
  }

  &__row {
    margin-bottom: 4rem;
  }

  &__col {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__text {
    width: 22rem;
  }

  .custom-button--preview span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
