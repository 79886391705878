@import '~antd/dist/antd.dark.css';
@import './variables';
@import './fonts';
@import './mixins';
@import './ant-overrides';
@import './reset.scss';

// components
@import '../components/index.scss';

// views
@import '../views/index.scss';

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  box-shadow: inset $secondary;
}

::-webkit-scrollbar-thumb {
  background-color: $secondary;
}

::-webkit-scrollbar-corner {
  display: none;
}

a {
  color: white;
}

html {
  scroll-behavior: smooth;
}