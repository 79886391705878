.active-contest-info {
  height: 4rem;
  font-size: 1.6rem;
  line-height: 4rem;
  margin-left: 4rem;
  &--green {
    color: $mountainMeadow;
  }
}
.contest-container, .settings-container.contest-container {
  .ant-table-cell .text-title {
    &--contest {
      margin-left: .7rem;
    }
    &--attempts {
      margin-left: 0;
    }
    &--active {
      font-size: 1.6rem;
      font-weight: 400;
    }
  }
  .active-contest {
    color: $mountainMeadow;
  }
  .inactive-contest {
    color:$danger;
  }
  .input-search {
    .ant-input::placeholder {
      font-size: 1.6rem !important;
    }
  }
  .ant-table-wrapper {
    width: 100%;
  }
  .overview-table {
    margin-top: 3.5rem;
  }
  .input-search {
    margin-bottom: 1.6rem;
  }
  .active-contest-info {
    font-size: 2rem;
    margin-left: 0.3rem;
  }
}