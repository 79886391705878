.settings-container {
  overflow-y: scroll;
  padding: 34px 20px 0 20px;
  height: calc(100vh - 11.2rem);
  display: block;

  &__col {
    width: 100%;
  }

  .input-search {
    height: 4rem;
    width: 27.4rem;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    background-color: $veryDarkGrey;
    border: 1px solid $buttonBorderColor;
    font-size: 1.6rem !important;
    font-family: 'SF Pro Text', sans-serif !important;
    font-weight: $normal !important;
    color: rgba(240, 240, 240, 0.4) !important;
    margin-bottom: 36px;

    &:hover {
      border: 0.5px solid #177ddc;
    }

    .ant-input-group-addon {
      display: none;
    }

    .ant-input::placeholder {
      font-size: 1.4rem !important;
      font-family: 'SF Pro Text', sans-serif !important;
      font-weight: $normal !important;
      color: rgba(240, 240, 240, 0.4) !important;
    }

    .ant-input-affix-wrapper-focused {
      border-radius: 0.8rem !important;
      border: 0.8px solid #177ddc;
      height: 4rem;
    }

    .ant-input-affix-wrapper {
      border: none;
      .ant-input {
        font-family: 'SF Pro Text', sans-serif;
        font-weight: $medium;
        font-size: 1.6rem !important;
        color: $rowWhite !important;
      &::placeholder {
        font-weight: $medium !important;
        font-size: 1.6rem !important;
        color: $rowFoggyWhite !important;
      }
      }

      .ant-input-prefix {
        color: $primary;
      }
    }

    .ant-input-search-button {
      padding: 0 0.8rem;
      background-color: $dark;
      border-radius: 0.8rem;
      outline: none;
    }
  }

  .ant-tabs-tab {
    font-size: 1.6rem;
    font-family: 'SF Pro Text', sans-serif;
    font-weight: 400;
    background: $veryDarkGrey;
    border: 1px solid rgba(242, 242, 242, 0.12);
    margin: 0;
    padding: 8px 16px;
    color: $porcelain;
    margin-bottom: 40px;

    & + .ant-tabs-tab {
      border-left: none;
    }

    .ant-tabs-tab-btn:active {
      color: $porcelain;
    }

    &:first-child.ant-tabs-tab-active:after,
    &:nth-child(2).ant-tabs-tab-active:after,
    &:nth-child(3).ant-tabs-tab-active:after,
    &:nth-child(4).ant-tabs-tab-active:after,
    &:nth-child(5).ant-tabs-tab-active:after,
    &:nth-child(6).ant-tabs-tab-active:after {
      left: calc((100% - 8px) / 2);
    }
  }

  .tab-pane-users {
    padding-top: 10px;
    table {
      tr th {
        &:nth-child(3) {
          padding-left: 4.7rem;
          display: flex;
          justify-content: flex-end;
        }
      }
    }}

  .ant-tabs-tab-active {
    background: $dodgerBlue;
    font-weight: 400;

    &:after {
      content: '';
      height: 8px;
      width: 8px;
      background: $dodgerBlue;
      position: absolute;
      top: 50px;
      border-radius: 50%;
      cursor: default;
    }

    .ant-tabs-tab-btn {
      color: $porcelain;
      font-family: 'SF Pro Text', sans-serif;
      font-size: 1.6rem;
      font-weight: 400;
    }
  }

  .ant-tabs-tab-btn {
    font-size: 1.6rem;
    font-family: 'SF Pro Text', sans-serif;
    font-weight: 400;
  }

  .ant-tabs-ink-bar {
    background: transparent;
  }
  .ant-tabs-nav {
    margin-bottom: 0.1rem;
  }
  .ant-tabs-nav:before {
    border: none;
  }

  .ant-table table {
    margin-top: -30px;
  }

  .button {
    &--add-user {
      margin-right: -14px;
      justify-items: right;

      .anticon-plus {
        padding-top: 3px;
      }
    }

  }
  table {
    .ant-table-thead {
      margin-top: 0;
    }
    tr th {
      &:first-child {
        padding-left: 15px;
      }
    }

    .button-delete {
      line-height: 0;
      padding-top: 4px;
    }
  }

  .users-table-row {
    > .ant-table-cell {
      padding: 5px 5px;
    }

    &.ant-table-row {
      height: 74px !important;
      border: 1.1px solid #3d3d3d !important;
    }

    td {
      &:first-child {
        max-width: 300px;
        min-width: 300px;
        padding-right: 15px !important;
        padding-left: 15px;
      }

      &:nth-child(2) {
        padding-left: 15px;
      }

      &:nth-child(3) {
        padding-left: 45px;
        max-width: 500px;
        min-width: 500px;
      }
    }

    .button-delete {
      margin-right: 10px;
    }

    .ant-select .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
    }

    .ant-select-single.ant-select-open .ant-select-selection-item {
      color: $mercury;
    }

    .ant-select.ant-select-single,
    .ant-select.ant-select-multiple {
      .ant-select-selector {
        background-color: #4d4d4d;

        .ant-select-selection-item {
          font-size: 16px;
          line-height: 40px;
        }
      }

      .ant-select-arrow {
        color: $mercury;
        z-index: 2;
      }
    }

    .ant-select.ant-select-single.ant-select-disabled,
    .ant-select.ant-select-multiple.ant-select-disabled {
      .ant-select-selector {
        background-color: $veryDarkGrey;

        .ant-select-selection-item {
          font-size: 1.6rem;
          font-family: 'SF Pro Text', sans-serif;
          font-weight: 400;
          color: $mercury;
          opacity: 0.3;
        }
      }

      .ant-select-arrow {
        color: $mercury;
        opacity: 0.2;
        z-index: 2;
      }
    }
  }
  .role-column {
    width: 16rem;
    max-width: 16rem;
  }

  .multiple-select {
    min-width: 100%;

    .ant-select-selection-item,
    .ant-select-selector {
      cursor: pointer;
    }

    .ant-select-selection-search input,
    .ant-select-selector {
      cursor: pointer !important;
    }

    .ant-select-selection-placeholder {
      right: auto;
      left: 10px;
    }

    .ant-select-selection-overflow {
      flex-wrap: wrap;
    }
  }

  .tab-pane-multiple-row {
    table {
      tbody {
        border: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 99%;

        .ant-table-row {
          min-width: 48%;
          margin-top: 10px;
          height: 74px !important;
          border: 1.1px solid #3d3d3d;

          td {
            width: 100%;
          }
        }

        & > tr > td {
          border-bottom: none !important;
        }
      }

      thead th {
        border: none;
      }
    }
  }

  .tab-pane-multiple-categories-row {
    width: 105%;

    @media only screen and (min-width: 1367px) {
      width: 103.5%;
    }

    .add-item-container {
      width: 45%;

      @media only screen and (min-width: 1367px) {
        width: 46.4%;
      }
    }
  }

  .ant-pagination-options-size-changer {
    .ant-select-dropdown-placement-bottomLeft {
      top: -120px !important;
    }
  }

  .add-item-container {
    width: 48%;
    height: 74px;
    background: $veryDarkGrey;
    position: relative;
    top: -35px;
    border: 1px solid rgba(242, 242, 242, 0.12);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.6rem;

    @media only screen and (max-width: 1367px) {
      width: 47.5%;
    }

    .ant-form-item-explain-success {
      display: none;
    }

    .select-permission-container {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      margin-right: 20px;
      width: 100%;

      &__button-with-permissions {
        text-align: right;
      }

      &__icon-permissions {
        margin-top: 2px;
        color: #3d6ff8;
      }
    }

    &__item {
      margin: 0;
      width: 90%;
      position: relative;

      .ant-form-item-explain.ant-form-item-explain-error {
        position: absolute;
        top: 4.5rem;
        z-index: 1;
        width: 50rem;
      }
    }
    &__input {
      font-size: 16px;
      border: none;
      box-shadow: none;
    }
    .ant-input:hover,
    .ant-input:focus,
    .ant-input:active {
      border: none;
      box-shadow: none !important;
    }

    &__paragraph {
      font-size: 16px;
      color: $boulder;
      margin-right: 20px;
      // width: 100%;
      // text-align: right;
    }

    .add-question-button {
      text-align: right;
      margin-right: 30px;

      &:hover svg path {
        fill: $stormDast;
      }
    }

    .selected {
      &:hover svg path {
        fill: $mountainMeadow;
      }
    }
  }
}

.roles-id-in-modal,
.roles-name-in-modal {
  display: none;
}

.modal-in-settings {
  .ant-modal-header {
    background: none;
    border-bottom: none;
    margin: 0 4rem;
    padding: 3rem 0;

    .ant-modal-title {
      @include fontStyles(1.8rem, $normal);
      color: $primeWhite;
    }
  }

  .ant-select-single .ant-select-selection-placeholder {
    line-height: 40px;
  }

  .form {
    width: 100%;
    height: 100%;

    &__item {
      margin: 0 4rem;
      min-height: 13rem;
      min-width: 20rem;
      max-width: 100%;

      &--checkbox {
        min-height: 4rem;
      }
    }

    &__heading {
      @include fontStyles(1.8rem, $normal);
      margin: 4rem;
      color: $primeWhite;
    }

    &-item {
      &__label {
        font-size: 1.4rem;
        color: $stormDast;
      }

      &__input {
        height: 4rem;
        font-size: 1.6rem;
        background: $veryDarkGrey !important;
        border-radius: 0.8rem;
        // border:1px solid red;
      }

      &__select {
        width: 100%;

        .ant-select.form-item__select {
          .ant-select-multiple {
            min-height: 4rem;
          }
        }

        .ant-select-selector {
          min-height: 4rem;

          .ant-select-selection-item {
            display: flex;
          }
        }

        &-option {
          margin-right: 10px;
        }
      }
    }
  }

  .action-container {
    padding: 0 4rem;
    width: 100%;
    @include flex(center, space-between);
    align-items: flex-end;
    margin-bottom: 3rem;

    &--center {
      justify-content: center;
      margin-top: 15px;
    }
  }

  .anticon-plus {
    padding-top: 2px;
  }
}

.access-requests-table {
  margin-top: 2rem;
  .ant-table-placeholder {
    display: none;
  }
}

.reset-button {
  width: 0;
  height: 0;
  background: transparent !important;
  border-color: transparent !important;
  color: $boulder !important;
}

.reset-button:hover {
  background: transparent !important;
  border-color: transparent !important;
}

.reset-button:focus {
  background: transparent !important;
  border-color: transparent !important;
}
