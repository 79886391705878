.upload-icon-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  .file-icon-dark {
    width: 16px !important;
    height: 16px !important;
    display: inherit;
  }
}
.file-upload {
  .ant-upload-list,
  .ant-upload-list-picture {
    margin-top: 5rem !important;
    .ant-upload-list-item-list-type-picture {
      border-radius: 0.8rem;
      width: 30.2rem;
      background-color: $veryDarkGrey;
    }
  }
  a:hover {
    cursor: default !important;
    color: aliceblue !important;
  }
}

.file-container {
  height: 17rem;
  background-color: $veryDarkGrey;
  border-radius: $radius-sm;
  border: 1px dashed $tundora;
  margin-top: 8.4rem;
  cursor: pointer;
  transition: 0.3s;
  &__col {
    @include flex($justify: center, $align: center);
    flex-direction: column;
    .plus {
      color: $primary;
      margin-right: 1.2rem;
    }

    .description {
      margin-top: 2.1rem;
      text-align: center;
      color: $brightGrey;
    }
  }
}

.ant-upload {
  width: 100%;
}

.file-container:hover {
  background: #303030;
}

.file-warning {
  margin-right: 0.6rem;
}
.file-warning-first {
  font-family: SF Pro Display, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 2.1rem;
  margin-left: 1.5rem;
}
