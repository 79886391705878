.finish-text {
  &--welcome {
    font-size: 2.1rem;
    margin-bottom: 1rem;
  }
  &--luck {
    margin-top: 1.5rem;
  }
  &--lower {
    &--finished {
      margin-top: 0.7rem;
      text-align: center;
    }
  }
}

.btn-container {
  display: flex;
  justify-content: center;
}

.btn-pointer {
  cursor: pointer;
}

