// *** INITIAL STATE ***
.custom-button {
  @include flex-center;
  height: 4rem;
  font-family: 'SF Pro Text', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: $primeWhite !important;
  letter-spacing: 0.8px;
  border-radius: 0.8rem;
  border: 1px solid #6089FB;
  background: $primary;
  outline: none;
  transition: 0.2s;
  padding: 0.8rem 1.2rem;

  .ant-btn {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    font-family: "SF Pro Text", sans-serif;
  }
  // *** HOVER STATE ***

  &:hover,
  &:focus {
    background: #597ef7 !important;
  }
  // *** MODIFICATORS ***
  &--green {
    background: #07a065;
    border: none;
    span{
      font-family: 'SF Pro Text', sans-serif;
      font-size: 1.6rem;
      font-weight: 500;
      }
    &:hover,
    &:focus {
      background: #09ba76 !important;
    }
  }

  &--preview {
    width: 8.3rem;
    height: 3.6rem;
    font-weight: 400;
    font-size: 1.6rem;
    border: solid 1px $tundora;
    border-radius: 0.6rem;
    background-color: $veryDarkGrey;
    &:focus {
      background-color: $veryDarkGrey !important;
      border: solid 1px $tundora;
    }
    &:hover {
      color: white;
      border: solid 1px $tundora;
      background-color: $dodgerBlue !important;
    }
  }

  &--submit {
    height: 4rem;
    border: solid 1px #6089fb;
    border-radius: 0.8rem;
    color: rgb(216, 211, 211);
    span{
      font-family: 'SF Pro Text', sans-serif;
      font-size: 1.6rem;
      font-weight: 500;
      }
    a{
      font-family: 'SF Pro Text', sans-serif;
      font-size: 1.6rem;
      font-weight: 500;
    }
    &-question {
      margin-top: 4rem;
      padding: 0.8rem 2rem;
      letter-spacing: 0.8px;
      span{
      font-family: 'SF Pro Text', sans-serif;
      font-size: 1.6rem;
      font-weight: 500;
      }
    }
    &-start {
      padding: 0.8rem 2rem;
      font-size: 1.6rem;
    }

    &:hover,
    &:focus {
      border: solid 1px #6089fb;
    }
  }

  &--finish {
    padding: 0.8rem 1.2rem;
    border: 2px solid $danger;
    background-color: #e06c76;
    font-size: 1.6rem;
    font-family: 'SF Pro Text', sans-serif;
    letter-spacing: 0.8px;
    span {
      font-size: 1.6rem;
      font-family: 'SF Pro Text', sans-serif;
      font-weight: 500;
    }

    &-info {
      color: #f2f2f2;
      background: #0fb474;
      border: 1px solid #26d18f;
      border-radius: 20px;
      padding: 8px 16px;
      margin-top: 5rem;
      margin-bottom: 5.8rem !important;
      &:hover,
      &:focus {
        background: #26d18f !important;
        border-radius: 20px !important;
      }
    }
    &:hover,
    &:focus {
      background-color: #ed828b !important;
      border: solid 2px #ed828c !important;
    }
  }

  &--resign {
    height: 4rem;
    color: $porcelain;
    background-color: $tundoraLigh;
    border: solid 1px #606060;
    span {
      font-size: 1.6rem;
    font-weight: 400;
    font-family: 'SF Pro Text', sans-serif;
    }

    &:hover,
    &:focus {
      background-color: #606060 !important;
      color: rgba(255, 255, 255, 0.8);
      border: solid 1px #606060 !important;
    }
  }

  &--accept {
    color: $porcelain;
    background-color: $danger;
    border: 1px solid $danger;
    &:hover,
    &:focus {
      background-color: $seaPinkHover !important;
    }
  }

  &--info {
    color: $porcelain;
    background-color: $tundoraLigh;
    &:hover,
    &:focus {
      background-color: $tundoraLigh !important;
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }

  &--clear {
    background-color: $secondary;
    border: 0.5px solid $secondary;
    color: rgba(229, 229, 229, 0.65) !important;
    span{
      font-family: 'SF Pro Text', sans-serif;
      font-size: 1.6rem;
      font-weight: 400;
      }
    &:hover,
    &:focus {
      background-color: #606060 !important;
      color: rgba(255, 255, 255, 0.8);
      border: solid 1px #606060 !important;
    }
  }

  &--edit {
    padding: 0.9rem 1.8rem;
    background-color: #2d2d2d;
    border: solid 1px #454545;
    font-weight: 400;
    font-size: 1.6rem;

    &-large {
      width: 9.2rem;
    }
    &:focus {
      border: solid 1px #454545 !important;
      background-color: #2d2d2d !important;
    }
    &:hover {
      border: solid 1px #6089fb !important;
      background-color: #3d6ff8 !important;
    }
  }

  &--generate {
    padding: 0.9rem 1.8rem;
    background-color: #2d2d2d;
    border: solid 1px #454545;
    font-weight: 400;
    font-size: 1.6rem;
    &:focus {
      border: solid 1px #454545 !important;
      background-color: #2d2d2d !important;
    }
    &:hover {
      border: solid 1px #6089fb !important;
      background-color: #3d6ff8 !important;
    }
  }

  &--disabled {
    color: #5e5e5e !important;
    background: #3f3f3f !important;
    border: solid 1px #545454;
    &:focus,
    &:hover {
      border: solid 1px #545454;
      color: #5e5e5e !important;
      background-color: #3f3f3f !important;
    }
  }

  &--notification {
    margin-top: 5rem;
  }

  &--excel {
    padding: 0.9rem 1.8rem;
    height: 3.6rem;
    margin-left: 4rem;
    background-color: #07a065;
    border: solid 1px $tundora;
    font-weight: 400;
    font-size: 1.6rem;
    &:focus {
      border: solid 1px $tundora !important;
      background-color: $mountainMeadow !important;
    }
    &:hover {
      border: solid 1px $tundora !important;
      background-color: #09ba76 !important;
    }
  }
}
