// All variables goes here

// figma colors
$stormDast: #616161;
$porcelain: #f2f2f2;
$mountainMeadow: #26D18F;
$dark: #252525;
$tundora: #454545;
$tundoraLigh: #4c4c4c;
$gallery: #f0f0f0;
$silverChalice: #a8a8a8;
$brightTurquoise: #35f3aa;
$mountainMeadowL: #1dd78f;
$brightSun: #ffcc49;
$cornflowerBlue: #597ef7;
$mineShaft: #242424;
$boulder: #7c7c7c;
$warning: #eabd4d;
$primary: #3d6ff8;
$secondary: #4d4d4d;
$light: #fefefe;
$danger: #e06c76;
$success: #0acf83;
$blackBackground: #0a0a0c;
$subtitle: #a3a9b7;
$darkblue: #2f54eb;
$white: #ffffff;
$hidden: #3a3a3a;
$hidden-text: #474545;
$offWhite: #e5e5e5;
$rowWhite: rgba(255, 255, 255, 0.85);
$rowFoggyWhite: rgba(255, 255, 255, 0.3);
$lightBackground: #313030;
$primaryBackgroud: #1b1b1b;
$codeMirrorBackground: #222222;
$secondaryCodeMirrorBackground: #2d2d2d;
$CharlestonGreen: #2d2d2d;
$lightGreen: #0fb474;
$primeRed: #f30057;
$graniteGrey: #616161;
$platinium: #e5e5e5;
$tundora: #4c4c4c;
$mineShaft: #3a3a3a;
$primaryBackgroud: #1b1b1b;
$raisinBlack: #222222;
$veryDarkGrey: #2d2d2d;
$lightGreen: #0acf84;
$primeRed: #f30057;
$primeYellow: #ffcc49;
$primeWhite: #f0f0f0;
$darkGrey: #363636;
$darkerGrey: #3d3d3d;
$paleRed: #ffa39e;
$lightGray: #595959;
$brightGrey: #a8a8a8;
$darkGrayishRed: #7e7c7c;
$tableBorderGray: rgba(242, 242, 242, 0.12);
$buttonBorderColor: #434343;
$java: #27aec0;
$monaLisa: #ffa39e;
$mercury: #e5e5e5;
$dodgerBlue: #3d6ff8;
$masala: #3e3433;
$alto: #d3d3d3;
$charade: #21242e;
$silverChalice: #a5a5a5;
$seaPink: #ed828c;
$seaPinkHover: #ed828b;
$scorpion: #606060;
$hedgehog: #707070;
$codGray: #161616;
$graphite: #323232;
$greenPae: #1a6d4d;
$niagara: #0db978;
$mineShaftDarker: #242424;
$darkSpringGreen: #1D6F42;
$mountainMeadowBright: #26D18F;
// // borders
$mainBorder: 1px solid $secondary;

// radius
$radius-xs: 0.4rem;
$radius-sm: 0.8rem;
$radius-md: 1.6rem;
$radius-l: 2.2rem;
$radius-lg: 2.4rem;
$radius-xl: 3.8rem;
$main-radius: 0.8rem;

// shadows
$common-shadow: 3px 6px 33px 0px rgba(61, 99, 248, 0.15);

// font-weight
$fontLight: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;

// font-size
$font-xs: 1.2rem;
$font-sm: 1.6rem;
$font-md: 1.8rem;
$font-lg: 2.4rem;
$font-xl: 3.8rem;

// gradients
$finish-button: linear-gradient(180deg, #f30092 0%, #f3004c 100%, #f3004c 100%);
// buttons
$active-button: #0FB474;
$inactive-button: #E06C76;
$inactive-button-bright: #ED828B;
