.form-item {
  .ant-select-single {
    background-color: $veryDarkGrey;
  }
  .ant-select-multiple {
    background-color: $veryDarkGrey;
  }

  &__select-tag {
    display: block;
    background-color: unset;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px !important;
    margin: 0;
  }
  &__select {
    .ant-select-selection-item {
      background-color: unset;
      border: none;
      font-size: 1.2rem;
      margin: 0;
      padding: 0;
      align-items: center;
      &-content {
        visibility: hidden;
        width: 0;
        position: relative;
      }
    }
    .ant-select-selector {
      z-index: 0;
    }
    &--option {
      .ant-select-dropdown {
        width: auto !important;
      }
      width: auto !important;
    }
  }
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.08);
  cursor: not-allowed;
  .ant-select-selection-overflow-item {
    span {
      .ant-tag.form-item__select-tag {
        color: rgba(255, 255, 255, 0.3);
        font-family: 'SF Pro Text', sans-serif;
      }
    }
  }
}

.ant-select.form-item__select.ant-select-single {
  font-size: 16px;
  background-color: $veryDarkGrey;
  border: 1px rgba(242, 242, 242, 0.12);
  border-radius: $radius-sm;
  .ant-select-selector {
    .ant-select-selection-item {
      font-size: 16px;
    }

    .ant-select-selection-search-input {
      height: 40px;
      font-size: 1.6rem;
      font-family: 'SF Pro Text', sans-serif;
      font-weight: 400;
    }
  }
}

.ant-select.form-item__select.ant-select-multiple {
  font-size: 16px;
}
