.element_remove {
  cursor: pointer;
}
.container_modal {
  border-radius: 3rem;
}
.subjects_container {
  display: flex;
  justify-content: center;
}
.subjects_record {
  width: auto;
  padding-left: 0.3rem;
}
.disable-action {
  pointer-events: none;
}
.button {
  background-color: $veryDarkGrey;
  height: 3.6rem;
  border-radius: 0.6rem;
  border: 1px solid transparent;
  padding-top: 0;
  padding-bottom: 0;
  &:hover :focus {
    background-color: $darkblue;
  }
  .ant-btn {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    border-radius: 0.6rem;
  }
}
