.add-test-modal {
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    height: 100%;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
  }

  .ant-modal-header {
    background: none;
    border-bottom: none;
    margin: 0 4rem;
    padding: 3rem 0;

    .ant-modal-title {
      @include fontStyles(1.8rem, $normal);
      color: $primeWhite;
    }
  }
}

.form {
  width: 100%;
  height: 100%;

  &__item {
    margin: 0 4rem;
    min-height: 13rem;
    min-width: 20rem;
    max-width: 100%;
  }

  &__heading {
    @include fontStyles(1.8rem, $normal);
    margin: 4rem;
    color: $primeWhite;
  }

  &-item {
    &__label {
      font-size: 1.4rem;
      color: $stormDast;
    }

    &__input {
      height: 4rem;
      font-size: 1.6rem;
      background: $veryDarkGrey !important;
      border-radius: 0.8rem;
      font-family: 'SF Pro Text', sans-serif;
    }

    &__test-option {
      padding: 0;

      .ant-select-item-option-content span:first-child {
        display: block;
        width: 100%;
        padding: 6px 0 8px 5px;
      }
    }

    &__select {
      width: 100%;

      .ant-select.form-item__select {
        .ant-select-multiple {
          min-height: 4rem;
        }
      }

      .ant-select-selector {
        min-height: 4rem;
      }

      &-option {
        margin-right: 10px;
      }
    }
  }
}

.action-container {
  padding: 0 4rem;
  width: 100%;
  @include flex(center, space-between);
  align-items: flex-end;
  margin-bottom: 3rem;
  &--save {
    padding: 0;
    width: 100%;
    @include flex(center, space-between);
    align-items: flex-end;
    margin-bottom: 3rem;
  }
}
