//Font Imports goes here
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text'), url('../fonts/SFProText/SFProText-Light-1.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text'), url('../fonts/SFProText/SFProText-Regular-1.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text'), url('../fonts/SFProText/SFProText-Medium-1.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text'), url('../fonts/SFProText/SFProText-Bold-1.ttf') format('truetype');
  font-weight: 500;
}

.sf-pro-text {
  font-family: 'SF Pro Text', sans-serif;
}

.sf-pro-text400 {
  font-family: 'SF Pro Text', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 700;
  src: url('../fonts/SFPRODISPLAYBOLD.OTF') format('truetype');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 500;
  src: url('../fonts/SFPRODISPLAYMEDIUM.OTF') format('truetype');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 400;
  src: url('../fonts/SF-Pro-Display-Regular.otf') format('truetype');
}

