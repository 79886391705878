.overview-contest-container.settings-container {
  position: relative;
  .ant-table-wrapper {
    width: 100%;
  }
  .active-contest-info {
    font-size: 2rem;
    margin-left: 0.3rem;
  }
  .overview-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .overview-table {
    margin-top: 3.5rem;
  }
  .text-title {
    &--participant {
      margin-left: 0.8rem;
    }
    &--email {
      margin-left: 0;
    }
    &--time {
      margin-left: 0;
    }
    &--result {
      margin-left: 0;
    }
    &--phone {
      margin-left: 0;
    }
  }
  .recruited-name-column {
    min-width: 30rem;
  }
  .recruited-email-column {
    min-width: 35rem;
  }
  .ant-pagination.ant-table-pagination.ant-table-pagination-center {
    position: relative;
  }
  .input-search {
    margin-bottom: 1.6rem;
    .ant-input::placeholder {
      font-size: 1.6rem !important;
    }
  }
}
.ant-modal-close .anticon {
  margin-left: 1rem;
}