input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

.generate-test-content {
  padding-left: 3.9rem;
  padding-right: 3.9rem;
  min-height: 90%;

  .ant-form-item-has-error .ant-input-number,
  .ant-form-item-has-error .ant-picker {
    border-color: $paleRed;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 21.48px;
  }
  .generate-test-button {
    margin-bottom: 3rem;
    height: 46px;
  }

  .checkbox-item {
    color: $mercury;
    span {
    font-family: 'SF Pro Text', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    }
  }
}

.font-weight {
  font-weight: 700;
  &--result {
    font-weight: 600;
  }
}

.form-item-custom {
  width: 46.2rem;
  color: white !important;
  height: 5.2rem;
  margin-bottom: 0;

  .custom-input {
    box-shadow: inset 0 0 0 1px #252525, inset 0 0 0 100px #252525;
    font-family: 'SF Pro Text', sans-serif;
    border-radius: 0.8rem;
    height: 4rem;
    font-size: 1.6rem;
    background: #2d2d2d !important;

    &:focus {
      background-color: #252525;
    }
    .ant-picker-panel {
      background: #684949;
    }
  }
  .date-picker {
    width: 95%;

    input {
      &::placeholder {
        font-size: 1.6rem;
      }
    }
  }
}

.container-icon-suffix {
  position: relative;
  .icon-suffix {
    position: absolute;
    top: 0.95rem;
    right: 1rem;
    z-index: 9999;
  }
}

.ant-picker-panel-container {
  background: #2d2d2d;
}
.nonvisible {
  visibility: hidden;
}
