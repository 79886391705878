.modal_result_container-row {
  height: 100%;
  .modal_result_container-col {
    height: 100%;
    .modal_result_container_internal-row {
      height: 100%;
      .modal_result_container_internal-col {
        height: 80%;
        border-radius: 22px;
        .modal_result_container_internal-row-content {
          height: 100%;
          .modal_result_container_internal-col-content {
            height: 90%;
            .modal_result_title {
              @include fontStyles(2.4rem, $bold);
              color: $white;
            }
            .time {
              font-size: $font-md;
              &__value {
                color: $success;
                text-align: right;
              }
            }
            .task__title {
              @include fontStyles(1.8rem, $bold);
              &-value {
                color: $success;
                text-align: right;
              }
            }
            .answer__time {
              @include fontStyles(1.8rem);
              &-value {
                font-weight: $bold;
              }
            }
            .answers_count_title_value {
              @include fontStyles(1.8rem, $bold);
            }
          }
        }
      }
    }
  }
}
.divide_line {
  border-bottom: 1px solid #6b6b6b;
}
.elements_top_margin {
  margin-top: 7%;
}
.element_top_margin_minimal {
  margin-top: 2%;
  padding-top: 5px;
}

.text_area_row {
  &:hover {
    cursor: default;
  }
}
.text_area {
  font-size: $font-sm;
  &:hover {
    cursor: default;
  }
}
.header2 {
  font-size: $font-md;
  margin-top: 1em;
}
.question_table {
  thead {
    tr {
      th {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
