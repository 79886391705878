.expire-time-popup {
  width: 100vw;
  height: 100vh;
  &-container {
    width: 43.6rem;
    background-color: $dark;
    border-radius: $radius-md;
    border: 1px solid $secondary;
    padding: 3.9rem 4.3rem;
    &__icon {
      background: linear-gradient(180deg, #d71cff 0%, #f30092 2.08%, #f3004c 100%, #f3004c 100%);
    }
    &__title {
      text-align: center;
      @include fontStyles(2.4rem, $bold);
      margin-top: 2.2rem;
    }
  }
}
