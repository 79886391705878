.modal-container {
  &__category {
    margin-top: 2.6rem;
  }
  &__question-content {
    width: 46.4rem;
    height: 9rem;
    word-wrap: break-word;
    margin-top: 1.3rem;
    line-height: 2.1rem;
  }
  .button-download {
    cursor: pointer;
}
  .button-download-container {
    margin-bottom: 4rem;
  }

  &__answers {
    margin-top: 2rem;
  }
  &__category-container {
    width: 90%;
    min-height: auto;
    white-space: normal;
  }
  &__subjects-modal {
    word-wrap: break-word;
  }
}
.manage-test-row {
  height: 100vh;

  .manage-test-col {
    min-width: 80%;
    .ant-table.content::after {
      z-index:0;
    }

    .container-row {
      height: calc(100vh - 26rem);
    }

    .ant-table-content {
      height: calc(100vh - 44.8rem);
      margin-top: 15rem;

      thead {
        display: none;
      }
    }

    .ant-pagination.ant-table-pagination.ant-table-pagination-center {
      position: static;
    }
  }

  .container-row {
    height: 100vh;

    .manage-test-table-container {
      min-width: 100%;
      height: 100vh;

      .table {
        height: calc(100vh - 11rem);
        width: max-content;
        //padding-right: 2.9rem;
        overflow-y: hidden;
        @media only screen and (min-width: 1825px) {
          padding-right: 0;
        }
      }

      .custom-table {
        &__col {
          max-width: calc(100% - 50px);

          table {
            margin-left: 19px;
          }
        }
      }
    }
  }

  .description-section {
    display: flex;
    flex-direction: column;
    width: calc(100% - 44.3rem);
    padding-left: 19px;

    .description-section__title {
      font-family: "SF Pro Display", sans-serif;
      font-size: 1.6rem;
      font-weight: 400;
      padding: 16px 16px 0;
      margin: 0 1.6rem 2.4rem 0;
      color: #f2f2f2;

    }

    .contest-description {
      width: 100%;

      .w-md-editor-content {
        height: 120px !important;
      }

      .w-md-editor-bar {
        display: none;
      }
    }
  }
}

.content_container-row {
  max-height: 100vh;

  .content_question_container-col {
    background-color: $dark;
    border-radius: 2.2rem;
    border: $mainBorder;

    .content_container_internal-row {
      height: 85%;

      .content_container_internal-col {
        .title_row {
          margin-top: 2.8rem;
        }

        .title_menu {
          margin-top: 2rem;
          font-weight: 700;
        }

        .menu {
          min-height: auto;
          margin-top: 1.4rem;

          .menu_container {
            display: flex;
            gap: 1rem;

            .level_container_group {
              width: 100%;
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .search_title {
          margin-top: 2.8rem;
          font-weight: 700;
        }

        .search_input_container {
          height: auto;
          margin-top: 2.8rem;

          &--row {
            width: 100%;
            border: 0.1rem solid orange;
          }

          .input_search {
            width: 100%;

            .ant-input-search-button {
              padding: 0 0.8rem;
              background-color: $primary;
              border: none;
              outline: none;
            }
          }
        }

        .searched_question_row {
          margin-top: 2.8rem;
        }

        .add_new_question_title {
          height: auto;
          margin-top: 1%;
          font-weight: 700;
        }

        .menu_question_container {
          display: flex;
          justify-content: space-between;
          height: 3.6rem;
          margin-top: 1.6rem;

          .button_type_question_container {
            display: flex;
            justify-content: space-between;

            .link_no_style {
              width: 32%;
              height: 8rem;
            }
            .button_type_question {
              display: flex;
              justify-content: center;
              font-size: 1.44rem;
              align-items: center;
              height: 100%;
              width: 20%;
              background-color: $dark;
              border-radius: 1rem;
              border: 0.2rem solid $primary;
            }
          }
        }
      }
    }
  }

  .menu_nav_container {
    margin-top: auto;
    margin-bottom: 1rem;
    position: relative;
    bottom: 1rem;
    right: 0;

    .menu_nav {
      display: flex;
      justify-content: space-between;
      min-height: 10rem;
      overflow: auto;

      .menu_nav_link {
        width: 100%;
        height: 100%;

        .previous_button {
          width: 30%;
          border-radius: 3.8rem;
          border: 0.2rem solid $primary;
        }
      }
    }
  }
}

.choosed_question_container-row {
  height: 100vh;
  background-color: $dark;
  border-left: $mainBorder;

  .choosed_question_container-col {
    margin-top: 2.8rem;
    height: 85%;

    .choosed_question_container_internal-row {
      height: 100%;

      .choosed_question_container_internal {
        height: 100%;

        .container_buttons_tabs-row {
          height: 10%;
          background-color: #0a0a0c;
          border-radius: 1.6rem;

          .container_buttons_tabs-col {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.5rem;
            width: 30%;

            .property_buttons_nav {
              padding: 0.5rem;
              margin: 0.2rem;
              border-radius: 1rem;
              width: 40%;
            }

            .un_choose_button {
              padding: 0.5rem;
              margin: 0.2rem;
              border-radius: 1rem;
              width: 40%;
              color: rgba(255, 255, 255, 0.4);
              border: 0.2rem solid #0a0a0c;
            }
          }
        }

        .title_searched_questions {
          display: flex;
          justify-content: space-between;
          margin-top: 2.8rem;
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 1.9rem;
          font-family: 'SFPro Display', sans-serif;
          padding: 0 15px 0 6px;
        }

        .questions_searched {
          margin-top: 10%;
          display: block;
          overflow-y: auto;
          height: 95%;

          .question-search-col {
            height: 100%;
          }

          .choosed-questions-list {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.9rem;
            font-family: 'SFPro Display', sans-serif;

            &__item {
              text-overflow: ellipsis;
              padding: 1.4rem 0.6rem;
              display: flex;
              justify-content: space-between;

              &:first-child {
                padding-top: 0;
              }
            }
          }

          .choosed_questions-row {
            margin-top: 5%;
          }
        }

        .edit_question_button-row {
          height: 5%;
          margin-top: 10%;

          .edit_question_button {
            height: 5rem;
            width: 100%;
            border-radius: 3.8rem;
          }
        }

        .remove_question_button-row {
          height: 5%;
          margin-top: 10%;

          .remove_question_button {
            height: 5rem;
            width: 100%;
            border-radius: 3.8rem;
            margin-top: 1rem;
            border: 0.2rem solid $primary;
          }
        }
      }
    }
  }
}

.button_col {
  margin-top: 1rem;

  .add_button {
    border-radius: 3.8rem;
    width: 20rem;
    height: 4.6rem;
  }
}

.code_mirror_width {
  width: 100%;
}

.table-striped-rowss tr:nth-child(2n) td {
  background-color: $dark;
}

.table-striped-rowss {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 1.4rem 1.6rem;
  }
}

.midle-part {
  max-height: 100vh;

  &__col {
    background-color: $dark;
    border-radius: 2.2rem;
    border: $mainBorder;
    margin-top: 2.8rem;
  }
}

.questions-choise-part {
  height: 85%;

  &__col {
    &__test-title {
      margin: 4.8rem 0 3.3rem;
    }

    h3.ant-typography,
    .ant-typography h3 {
      font-size: 2.4rem;
      line-height: 2.86rem;
      font-weight: 700;
      font-family: 'SF-Pro Display', sans-serif;
    }

    .divider {
      margin: 0 0;
    }

    .menu-title {
      margin: 1rem 0;
      font-size: 1.2rem;
      line-height: 2.2rem;
      font-weight: 700;
      font-family: 'SF-Pro Display', sans-serif;
    }

    .menu-container {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      height: 3.8rem;
    }

    .question-list-title {
      margin: 2.6rem 0 1.6rem;
      font-size: 1.8rem;
      line-height: 1.4rem;
      font-weight: 400;
      font-family: 'SF-Pro Display', sans-serif;
    }

    th.ant-table-cell {
      display: none;
    }
  }
}

.confirm-button {
  margin: 3.7rem auto 2rem auto;
  border-radius: 3.8rem;
  width: 20rem;
  height: 4.6rem;
}

.input_search {
  width: 100%;

  .ant-input-search-button {
    padding: 0 0.8rem;
    background-color: $primary;
    border: none;
    outline: none;
  }
}

.select_category {
  width: 100%;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: $dark;
}

.table-striped-rows {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 1.4rem 1.6rem;
  }
}

// classes for QuizzesEditRow
.list-row {
  width: 100%;
}

.list-col {
  display: flex;
  flex-direction: row;
}

.peaky-eye-container {
  padding: 0.3rem;
}

.indicator {
  padding-left: 1rem;
  font-weight: 600;
}

.peaky-eye {
  position: relative;
  right: 0;
  left: auto;
  top: 0.7rem;
  font-size: 2rem;
}

.checkbox {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0;

  .row-in-ist {
    border: 0.1rem solid purple;
    width: 100% !important;
  }
}

//right side panel used classes
.choosed-question-container-row {
  height: 100vh;
  background-color: $dark;
  border-left: $mainBorder;

  &__col {
    margin-top: 2.8rem;
    height: 85%;

    .content-row {
      height: 100%;

      &__col {
        height: 100%;
      }
    }
  }
}

.lack-question {

  text:last-child {
    font-size: 12px;
  }
}

.side-buttons-container {
  height: 10%;
  background-color: #0a0a0c;
  border-radius: 1.6rem;
  justify-content: space-around;
  align-items: center;
  font-size: 0.5rem;

  &__button {
    padding: 0.5rem;
    margin: 0.2rem;
    border-radius: 1rem;
    width: 40%;

    &--unselected {
      color: rgba(255, 255, 255, 0.4);
      border: 0.2rem solid #0a0a0c;
    }
  }
}

.choosed-list-header {
  display: flex;
  justify-content: space-between;
  margin-top: 2.8rem;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.9rem;
  font-family: 'SFPro Display', sans-serif;
  padding: 0 15px 0 6px;
}

.choosed-list-container {
  margin-top: 10%;
  display: block;
  overflow-y: auto;
  height: 95%;

  &__col {
    height: 100%;
  }

  .choosed-list {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
    font-family: 'SFPro Display', sans-serif;

    &__item {
      text-overflow: ellipsis;
      padding: 1.4rem 0.6rem;
      display: flex;
      justify-content: space-between;

      &:first-child {
        padding-top: 0;
      }
    }
  }
}

.test-details {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2d2d2d;
  padding: 0 0.5rem;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 42rem;

  .container__form {
    background-color: #2d2d2d;
  }

  &__title {
    @include flex-center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin: 3.5rem 3rem;
    background-color: #3f3f3f;
    border: solid 1px rgba(242, 242, 242, 0.12);
    border-radius: 0.8rem;

    &-text {
      color: #f0f0f0;
      font-size: 1.8rem;
    }
    &-arrow:hover {
      cursor: pointer;
    }
    &-clock {
      @include flex;
      color: $success;
      font-size: 1.4rem;

      &-icon {
        margin-right: 0.5rem;
      }
    }
    &-item {
      margin: 0;
    }
  }

  &__warning {
    background-color: #2d2d2d !important;
    padding: 0;
    margin: 1rem;
    border: solid 1px rgba(242, 242, 242, 0.12);
    border-radius: 0.8rem;
    height: 4rem;
    position: relative;
    &::after {
      content: 'No questions added';
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      margin-left: 1rem;
      z-index: 2;
    }
    &-item {
      background-color: #2d2d2d !important;
      color: rgba(0, 0, 0, 1) !important;
      height: 4rem;
      border-radius: 0.8rem;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      display: none !important;
    }
  }

  &__questions {
    width: 100%;
    margin-top: 4rem;
    padding: 0.5rem;
    overflow-y: scroll;
    flex-grow: 1;

    ul {
      .ant-form-item {
        margin-bottom: 0;
        margin-left: 10px;
      }
    }

    .ant-btn:focus {
      background: none;
      border: none;
    }
    .ant-btn:focus {
      background: none;
      border: solid 1px #4c4c4c !important;
    }
    &-item {
      overflow-x: hidden;
      border: 1px solid rgba(0, 0, 0, 0);
      .ant-form-item-control-input-content {
        width: 100%;
        font-family: 'SF Pro Text', sans-serif;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 12px 10px;
        border-radius: 8px;
        overflow: hidden;
      }
      &-title {
        font-size: 1.6rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 60%;
        overflow: hidden;
      }

      &-button {
        position: absolute;
        left: 0;
        padding: 0;
        border: none;

        span {
          z-index: -1;
        }

        &.up {
          margin-bottom: 0.4rem;
          margin-left: 1rem;
          transform: translateY(-1.5rem);
          opacity: 0;
          .icon svg {
            width: 0.8rem;
          }
        }

        &.down {
          margin-left: 1rem;
          margin-top: 0.4rem;
          transform: translateY(1.5rem);
          opacity: 0;
        }
        .icon svg {
          width: 0.8rem;
        }
      }
      &-container {
        @include flex;
      }

      &-preview {
        width: 8.3rem;
        height: 3.6rem;
        border: solid 1px $tundora;
        border-radius: 0.6rem;
        background-color: $veryDarkGrey;
        margin-left: 1rem;
      }

      &-icon {
        display: flex;
        align-items: center !important;
        font-size: 2rem;
        color: #c4c4c4;
        margin: 0 1.5rem 0 2rem;
        border: none;
        padding: 0;
      }

      &:hover {
        background-color: #3f3f3f !important;
        cursor: pointer;
        border: 1px solid #3f3f3f;
        border-radius: 8px;
        .test-details__questions-item-preview {
          background-color: $dodgerBlue;
          color: rgb(216, 211, 211);
        }
        .down,
        .up {
          opacity: 1;
        }
      }
    }
  }

  &__file {
    width: 95%;
    height: 4rem;
    display: flex;
    align-items: center;
    margin: 3rem auto 0;
    cursor: pointer;
    color: #616161;
    background: #3a3a3a;
    border: 1px solid #4c4c4c;
    border-radius: 10px;

    a {
      width: 100%;
      display: flex !important;
      align-items: center;
      padding: 0 !important;
      margin: 0 10px !important;
      color: rgba(255, 255, 255, 0.85);

      svg {
        margin-right: 10px;
      }
    }
  }

  &__uploadpdf-title {
    color: #616161;
    font-size: 14px;
    width: 95%;
    margin: 0 auto;
    padding: 1.5rem;

    &::before {
      display: inline-block;
      margin-right: 4px;
      color: #a61d24;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  .test-type::before {
    display: inline-block;
    margin-right: 4px;
    color: #a61d24;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
  
  &__uploadpdf {
    width: 95%;
    margin: 0 auto;
    transition: 0.3s;
    border: 1px dashed #4c4c4c;
    border-radius: 10px;
    cursor: pointer;
    
    .ant-upload.ant-upload-select > .ant-upload {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #616161;
      font-size: 14px;
      padding: 1rem 0;
      transition: 0.3s;
    }

    .ant-upload.ant-upload-select + .ant-upload-list-text {
      padding: 0 0 1rem;
    }
  }

  &__uploadpdf:hover {
    background: #3a3a3a;
    
    .ant-upload.ant-upload-select > .ant-upload {
      color: #f0f0f0;
    }
  }

  .text {
    padding: 2.4rem 0 0 1rem !important;
    margin-left: 1.5rem;
    margin-bottom: -1rem;
    color: #616161;
  }
  &__confirmation {
    @include flex(baseline, space-between);
    height: 6rem;
    padding: 2rem 4rem 2rem 2rem;
    margin-bottom: 4.5rem !important;

    .form__item {
      margin: 0;
      min-height: unset;
      width: 20rem;
      .select-question-panel,
      .ant-select,
      .ant-select-single,
      .ant-select-selector {
        background: #3f3f3f;
        border: solid 1px rgba(242, 242, 242, 0.12);
        height: 4rem;
        border-radius: 0.8rem;
        .ant-select-selection-search-input {
          height: 100%;
        }
        .ant-select-selection-placeholder {
          margin-top: auto;
          margin-bottom: auto;
          font-family: "SF Pro Text", sans-serif !important;
        }
        .ant-select-selection-item {
          padding-top: 0.3rem;
        }
      }
      .ant-form-item-control-input {
        min-height: 4rem !important;
        font-family: "SF Pro Text", sans-serif;
      }
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      display: none;
    }
  }
}

.background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(22, 22, 22, 0.9);
}

.container {
  height: 46rem;
  width: 54rem;
  background-color: $mineShaft;
  &__form {
    display: flex;
    flex-direction: column;
    background-color: #242424;

    &-paragraph {
      padding-left: 4rem;
      font-size: 1.8rem;
      white-space: normal;
      color: $primeWhite;

      &:nth-of-type(1) {
        margin-top: 4rem;
      }

      &:nth-of-type(3) {
        margin-top: 2rem;
      }

      &:nth-of-type(4) {
        margin: 4rem 0;
        display: flex;

        .question-time__icon {
          color: $success;
        }

        div {
          margin-left: 1rem;
        }
      }

      &-sug {
        color: $success;
      }
    }
  }
}
.form-item {
  &__select-option {
    .ant-checkbox:hover::after {
      visibility: hidden;
    }
  }
}
