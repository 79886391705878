.card-info-badge {
  border-radius: $radius-lg;
  height: 100%;
  &__text {
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-family: 'SF-Pro Display', sans-serif;
    font-weight: 300;
    width: 100%;
    height: 5rem;
    white-space: unset;
  }
}

.square {
  width: 68px;
  height: 68px;
  border-radius: $radius-md;
  margin: 10% auto;

  &--positive-col {
    background: linear-gradient(180deg, #0acf83 0%, #36e19f 100%);
  }

  &--unfinished-col {
    background: linear-gradient(180deg, #3d6ff8 0%, #3e6cf8 97.92%);
  }

  &--wrong-col {
    background: linear-gradient(180deg, #d71cff 0%, #f30092 2.08%, #f3004c 100%, #f3004c 100%);
  }

  &--attempts-col {
    background: linear-gradient(180deg, #6266f4 0%, #6568d6 2.08%, #6266f4 100%, #8386e2 100%);
  }
}
