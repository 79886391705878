.custom-info {
  height: 100vh;
  text-align: center;
  &__card {
    text-align: center;
    border-radius: $radius-lg;
    border: $mainBorder;
    background-color: $dark;
    padding: 1.6rem;
  }
  &__title {
    font-family: 'SF-Pro Display', sans-serif;
    font-size: 2.4rem;
    line-height: 2.9rem;
    font-weight: 700;
  }
  &__description {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: 'SF-Pro Display', sans-serif;
    white-space: normal;
  }

  &__button {
    height: 53px;
    width: 100%;
    border-radius: $radius-xl;
    background-color: $primary;
    box-shadow: $common-shadow;
    font-weight: 500;
    font-size: 1.6rem;
  }
  div.ant-typography,
  .ant-typography p {
    margin-bottom: 2rem;
  }
}
