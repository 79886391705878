// All mixins goes here...
@mixin fontStyles($size: 1.6rem, $weight: $medium) {
  font-size: $size;
  font-weight: $weight;
}

@mixin flex($align: center, $justify: flex-start) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin clickable {
  cursor: pointer;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin buttonColor($color: $java) {
  color: $color;
}
