.table {
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    background: transparent;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid $darkerGrey;
    font-size: 1.6rem;
    font-family: 'SF Pro Text', sans-serif;
    font-weight: 400;
  }

  tbody {
    border: 1.1px solid $darkerGrey;
  }
}

.text-title {
  color: rgba(240, 240, 240, 0.4);
  padding-left: 0 !important;
  .ant-table-column-sorters {
    padding: 1.6rem 0 !important;
  }
  &--first {
    padding-left: 0 !important;
  }
  &--quantity {
    margin-left: -1.5rem;
  }
}
.first-text {
  padding-left: 0;
  &--subjects {
    white-space: nowrap;
  }
}
.regular-text {
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  padding-left: 0;
  text-align: left;
  &--number {
    text-align: center;
  }
}
.element_remove {
  cursor: pointer;
}

.container_modal {
  border-radius: 3rem;
}

.subjects_container {
  display: flex;
  justify-content: center;
}

.subjects_record {
  width: auto;
}

.ant-table-row {
  min-width: 100%;
  height: 7rem !important;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: 'SF-Pro Display', sans-serif;
  font-weight: $normal;
  background-color: $raisinBlack !important;
  color: $rowWhite;

  &:hover {
    background-color: $raisinBlack !important;
    border: 1.1px solid $lightGray !important;

    .button:not(.active-button, .inactive-button) {
      background-color: lighten($veryDarkGrey, 10%);
    }
  }
}

.disable-action {
  pointer-events: none;
}
.conditional-delete {
  .ant-btn {
    border-color: transparent !important;
    background-color: transparent;
    border: none !important;
  }
}
.duplicate-icon {
  vertical-align: baseline;
  &:hover {
    path {
      fill: $offWhite;
    }
  }
}
.delete-icon {
  vertical-align: baseline;
  path {
    fill: $offWhite;
  }
  &:hover,
  &:active,
  &:focus {
    path {
      fill: $danger;
    }
    .ant-btn:not([disabled]):hover {
      background-color: transparent;
    }
  }
  &--disabled {
    path {
      fill: $secondary;
    }
  }
}
.button-delete,
.button-duplicate {
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    border: transparent;
  }
}

.categoryTooltip {
  .ant-tooltip-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: solid 1px #454545;
    border-radius: 0.8rem;
    transform: translateX(-30px);
    .ant-tag {
      margin: 5px 0;
      font-size: 1.2rem !important;
      line-height: 2rem !important;
      padding: 1px 8px !important;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.cat {
  font-family: 'SF Pro Text', sans-serif;
  font-size: 1.2rem !important;
  line-height: 2.4rem;
  font-weight: 400;
  padding: 0.3rem 0.8rem;
  border: 0.1rem solid rgba(143, 197, 111, 1);
  border-radius: 0.2rem;
  background-color: rgba(143, 197, 111, 0.1);
  .ant-typography {
    overflow: clip !important;
    text-overflow: clip !important;
  }

  &--js {
    border: 0.1rem solid rgb(94, 133, 208);
    background-color: rgb(94, 133, 208, 0.1);
  }
  &--jv {
    border: 0.1rem solid rgba(39, 174, 192, 1);
    background-color: rgba(39, 174, 192, 0.1);
  }
  &--php {
    border: 0.1rem solid rgba(15, 180, 116, 1);
    background-color: rgba(15, 180, 116, 0.1);
  }
  &--c,
  &--c-sh,
  &--c-pp {
    border: 0.1rem solid rgba(234, 189, 77, 1);
    background-color: rgba(234, 189, 77, 0.1);
  }
  &--db,
  &--salesforce {
    border: 0.1rem solid rgba(232, 96, 96, 1);
    background-color: rgba(232, 96, 96, 0.1);
  }
  &--ai,
  &--distributed-sys {
    border: 0.1rem solid rgba(199, 94, 208, 1);
    background-color: rgba(199, 94, 208, 0.1);
  }
  &--python {
    border: 0.1rem solid rgba(255, 163, 158, 1);
    background-color: rgba(255, 163, 158, 0.1);
  }
  &--css {
    border: 0.1rem solid rgba(189, 158, 255, 1);
    background-color: rgba(189, 158, 255, 0.1);
  }
  &--sql {
    border: 0.1rem solid rgba(221, 140, 95, 1);
    background-color: rgba(221, 140, 95, 0.1);
  }
  &--db {
    border: 1px solid #e86060;
    background: #e860601a;
  }
  span {
    .ant-typography {
      text-overflow: clip !important;
    }
  }
}
.question-time {
  display: flex;
  align-items: center;
  margin: auto 0 1.9rem 0 !important;
}

.delete-modal {
  width: 370px !important;

  .ant-modal-content {

    button[aria-label="Close"] {
      display: none;
    }

    .ant-modal-header {
      display: flex;
      justify-content: center;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 3rem 5rem;


      button {
        border: none;
      }

      .custom-button--cancel {
        background: #474747;
      }

      .custom-button--cancel:hover {
        background: #2d2d2d !important;
      }

      .custom-button--delete {
        background: #E06C76;
      }

      .custom-button--delete:hover {
        background: #ED828B !important;
      }
    }
  }
}