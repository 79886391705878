.basic-statistics {
  height: 100vh;
  background-color: $dark;
  div.ant-typography,
  .ant-typography p {
    margin-bottom: 1rem !important;
  }
  &__title {
    text-align: center;
    font-family: 'SF-Pro Display', sans-serif;
    padding-top: 1.4rem;
  }
  &__description {
    text-align: center;
    font-family: 'SF-Pro Display', sans-serif;
    font-size: 1.6rem;
  }
  .table {
    margin: 0 2rem;
  }
}
