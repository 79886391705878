// here comes all overwrites of ant

.ant-radio-button-wrapper:not(:first-child)::before {
  // klasa powoduje powstanie pionowego bordera po lewej stronie radiobuttona bo width: 1px
  width: 0;
}
.ant-pagination.ant-table-pagination.ant-table-pagination-center {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}

[ant-click-animating-without-extra-node='true']::after {
  display: none !important;
}

.ant-table-column-has-sorters {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-table-content {
  background-color: $dark;
}

// .ant-typography {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

.ant-spin-dot {
  font-size: 45px !important;
  .ant-spin-dot-item {
    width: 20px !important;
    height: 20px !important;
  }
}

.ant-form-item-label > label.ant-form-item-required:before {
  content: unset;
}

.ant-select-item-option-selected {
  background-color: $mineShaft !important;
}

.ant-select-item-option-state {
  display: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $mountainMeadow;
  border-color: $mountainMeadow;
  border-radius: 0.4rem;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: $dark;
}

.ant-select-multiple .ant-select-selection-item {
  border: none;
  background: transparent;
}

.ant-select-focused svg path {
  fill: $platinium;
}

.ant-select.form-item__select.ant-select-multiple,
.ant-select-selector {
  background-color: $veryDarkGrey;
  border: 1px rgba(242, 242, 242, 0.12);
  border-radius: $radius-sm;
}
.ant-select-selection-overflow {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.ant-select-dropdown {
  background-color: $veryDarkGrey;
  padding: 0.8rem;
  border: solid 1px $tundora;
  border-radius: $radius-sm;
  box-shadow: none !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
  @include flex;
  @include fontStyles;
  color: $paleRed;
  margin-top: 1rem;
}

.ant-form-item-has-error .ant-input {
  border-color: $paleRed !important;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-color: $paleRed !important;
  min-height: 4rem;
  border-radius: $radius-sm;
}

.ant-select-selector {
  border-radius: 0.8rem !important;
}

.anticon-close-circle {
  //display: none;
}

.anticon-reload {
  margin-left: 2rem;
}

.ant-form-item-has-success .anticon-check-circle svg {
  display: none;
}

.ant-menu-vertical {
  border: none !important;
}

.ant-card-meta-avatar {
  padding-right: 0;
}

.ant-menu-item .ant-menu-item-icon {
  margin: 0 !important;
}

.ant-menu-item .anticon {
  margin-right: 0 !important;
}

.ant-menu-item-selected {
  background-color: inherit !important;
}

.ant-menu-item .anticon {
  min-width: unset;
}

.ant-menu-vertical .ant-menu-item {
  margin-top: 0;
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 0 !important;
}

.ant-upload-list-picture .ant-upload-list-item-error {
  border-color: $lightGreen;
}

.ant-upload-list-item-error .ant-upload-list-item-name {
  color: $lightGreen;
}

.anticon-delete svg path {
  fill: #ffa39e !important;
}

.ant-modal-content {
  border-radius: 0.4rem;
  border: 1px solid $darkerGrey;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  background: #242424;
}

.ant-modal-body {
  padding: 0;
}

.ant-modal-mask {
  background-color: rgba(22, 22, 22, 0.9);
}

.ant-checkbox-inner {
  width: 2rem;
  height: 2rem;
  background: $CharlestonGreen;
  border-radius: $radius-xs;
  border-color: $tundora;
}

.ant-checkbox-checked .ant-checkbox-inner {
  @include flex-center;
  width: 2rem;
  height: 2rem;
  background-color: $lightGreen;
  border-color: $lightGreen;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  left: 0.5rem;
  margin-right: 0.5rem;
  border-color: $dark;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $lightGreen;
  border-radius: $radius-xs !important;
}

.ant-checkbox-checked::after {
  border: 1px solid $lightGreen;
  border-radius: $radius-xs !important;
}

.ant-checkbox {
  width: 2rem;
  height: 2rem;
  margin-right: 1.2rem;
}

.ant-checkbox + span {
  padding-right: 0;
}

.ant-form-item-has-error .ant-input {
  border-radius: 0.8rem;
  border-color: $paleRed;
  background: $veryDarkGrey;
}

.ant-form-item-explain.ant-form-item-explain-error {
  display: flex;
  margin-top: 1.2rem;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: $paleRed;
  border-radius: 0.8rem;
  border-color: $paleRed;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-color: $paleRed !important;
  background: $veryDarkGrey;
}

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  border-color: $paleRed;
  background: $veryDarkGrey;
}

.ant-form-item-explain.ant-form-item-explain-error div[role='alert']::before {
  content: url('../assets/icons/exclamation-icon.png');
  margin: 0.4rem 1rem 0.4rem 0;
}

.ant-form-item-explain.ant-form-item-explain-error div[role='alert'] {
  display: flex;
  align-items: flex-start;
}

.ant-form-text {
  font-size: 1.6rem;
}

.ant-tag {
  font-size: 14px !important;
  &:hover {
    opacity: 1;
  }
}

.form-item__select-tag {
  font-size: 14px !important;
}

.ant-radio-wrapper .ant-radio-wrapper-disabled {
  color: $tundora !important;
  border-color: $tundora !important;
}

.ant-radio-disabled .ant-radio-inner {
  background: none !important;
}
.ant-radio-disabled + span {
  color: $tundora !important;
}
.ant-radio-disabled .ant-radio-inner {
  border-color: $graphite !important;
}

.ant-radio-disabled .ant-radio-inner {
  border-color: $tundora;
}

.custom-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: $tundora;
}

.ant-radio-disabled .ant-radio-inner::after {
  background-color: $greenPae !important;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #454545 !important;
}

.ant-select-item-option-content {
  font-weight: normal;
  font-size: 1.6rem;
  font-family: 'SF Pro Text', sans-serif;
  font-weight: 400;
}

.ant-table-tbody > tr > td:last-of-type {
  border-right: 1px solid $darkerGrey !important;
}

.ant-table-tbody > tr > td:first-of-type {
  border-left: 1px solid $darkerGrey !important;
}

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  align-items: flex-start;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #177ddc;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: rgba(255, 255, 255, 0.08);
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: white;
}

.ant-picker-time-panel-cell .ant-picker-time-panel-cell-selected {
  background: #177ddc;

}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  color: white;
}