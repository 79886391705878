.mobile-view {
  width: 100vw;
  height: 100vh;

  .info {
    width: 28rem;
    height: 41.4rem;
    border-radius: 2.2rem;
    margin: auto auto;
    background-color: $dark;
    text-align: center;

    &__title {
      margin: 6.8rem auto;
      h1.ant-typography {
        font-size: 2.8rem;
        font-weight: $bold;
        line-height: 3.3rem;
        font-family: 'SF Pro Display', sans-serif;
      }
    }
    &__logo {
      position: absolute;
      top: 4rem;
      left: 19.1rem;
    }
    &__text {
      margin: 0 auto;
      width: 26rem;
      font-size: 1.8rem;
      font-weight: $medium;
      line-height: 2.15rem;
      text-align: center;
      white-space: normal;
    }
    &__divider {
      background-color: $success;
      height: 0.1rem;
      margin: 7.7rem auto 3.1rem;
      min-width: 9rem;
      width: 9rem;
    }
    &__link {
      margin: 0 auto 3.1rem auto;
      font-size: 1.4rem;
      font-weight: $normal;
      line-height: 2.2rem;
      font-family: 'SF Pro Text', sans-serif;
      text-align: center;
    }
  }
}
